import * as THREE from 'three';

// console.log(THREE);
// // Scene
// const scene = new THREE.Scene()


// // Axes helper
// const axesHelper = new THREE.AxesHelper()
// scene.add(axesHelper)

// // Sizes
// const sizes = {
//     width: 800,
//     height: 600
// }

// // Camera
// const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height)
// camera.position.z = 3
// scene.add(camera)


// // Renderer
// const renderer = new THREE.WebGLRenderer({
//     canvas: document.querySelector('canvas.webgl')
// })
// renderer.setSize(sizes.width, sizes.height)
// renderer.render(scene, camera)

let container;

let camera, scene, renderer;

	init();
	animate();

	function init() {

		container = document.getElementById( 'container' );

		camera = new THREE.PerspectiveCamera( 50, window.innerWidth / window.innerHeight, 1, 10 );
		camera.position.z = 2;

		scene = new THREE.Scene();
		scene.background = new THREE.Color( 0x101010 );

		// geometry
		const numCircles = 150 * 9; 
		const geometry = new THREE.BufferGeometry();


		const positions = [];
		const colors = [];

		for (let i = 0; i < numCircles; i++) {
      const x = (Math.random() - 0.5);  // x
      const y = (Math.random() - 0.5);  // y
      const z = (Math.random() - 0.5);  // z

      positions.push(x, y, z);

      colors.push(Math.random() * 255);
      colors.push(Math.random() * 255);
      colors.push(Math.random() * 255);
      colors.push(Math.random() * 255);
    }


		const positionAttribute = new THREE.Float32BufferAttribute( positions, 3 );
		const colorAttribute = new THREE.Uint8BufferAttribute( colors, 4 );

		colorAttribute.normalized = true; // this will map the buffer values to 0.0f - +1.0f in the shader

		geometry.setAttribute( 'position', positionAttribute );
		geometry.setAttribute( 'color', colorAttribute );

		// material
		const material = new THREE.RawShaderMaterial( {
			uniforms: {
        time: { value: 1.0 },
        center: { value: new THREE.Vector3( 0, 0, 0 ) }
      },
			vertexShader: document.getElementById( 'vertexShader' ).textContent,
			fragmentShader: document.getElementById( 'fragmentShader' ).textContent,
			side: THREE.DoubleSide,
			transparent: true
		} );

		const circles = new THREE.Points(geometry, material);
    scene.add(circles);

		renderer = new THREE.WebGLRenderer();
		renderer.setPixelRatio( window.devicePixelRatio );
		renderer.setSize( window.innerWidth, window.innerHeight );
		container.appendChild( renderer.domElement );

		window.addEventListener( 'resize', onWindowResize );

	}

	function onWindowResize() {
		camera.aspect = window.innerWidth / window.innerHeight;
		camera.updateProjectionMatrix();
		renderer.setSize( window.innerWidth, window.innerHeight );
	}


	function animate() {

		requestAnimationFrame( animate );

		render();

	}

	function render() {

		const time = performance.now();

		const object = scene.children[ 0 ];

		object.rotation.y = time * 0.00025;
		object.material.uniforms.time.value = time * 0.0009;

		renderer.render( scene, camera );

	}